import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
import ChartPieIcon from '../../icons/ChartPie';
// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
// import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const customerSections = [
  {
    title: 'Customer',
    items: [
      {
        title: 'Publishers',
        path: '/merchantList',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Latest Published',
        path: '/assets',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Purchase Activities',
        path: '/customerPurchaseActivities',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Payment Activities',
        path: '/customerPaymentActivities',
        icon: <ShoppingBagIcon fontSize="small" />
      },

    ]
  }
];

const merchantSections = [
  {
    title: 'Merchant',
    items: [
      {
        title: 'Sales Activities',
        path: '/merchantSaleActivityList',
        icon: <ChartPieIcon fontSize="small" />
      },
      {
        title: 'Merchant Assets',
        path: '/merchantAssets',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Cash Out Requests',
        path: '/merchantRedemptionList',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Create Cash Out Request',
        path: '/createRedemption',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Asset Upload',
        path: '/assetUpload',
        icon: <UserIcon fontSize="small" />
      },

    ]
  }
];

const operatorSections = [
  {
    title: 'Operator',
    items: [
      {
        title: 'Customer Payment Received',
        path: '/customerPayReceived',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Approved Cash Out Requests',
        path: '/approvedRedemptions',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'New Cash Out Requests',
        path: '/allNewRedemptions',
        icon: <UserIcon fontSize="small" />
      },

    ]
  }
];

const customerMerchantSections = [
  {
    title: 'As Customer',
    items: [
      {
        title: 'Publishers',
        path: '/merchantList',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Latest Published',
        path: '/assets',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Purchase Activities',
        path: '/customerPurchaseActivities',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Payment Activities',
        path: '/customerPaymentActivities',
        icon: <ShoppingBagIcon fontSize="small" />
      },
    ]
  },
  {
    title: 'As Publisher',
    items: [
      {
        title: 'Sales Activities',
        path: '/merchantSaleActivityList',
        icon: <ChartPieIcon fontSize="small" />
      },
      {
        title: 'Merchant Assets',
        path: '/merchantAssets',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Cash Out Requests',
        path: '/merchantRedemptionList',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Create Cash Out Request',
        path: '/createRedemption',
        icon: <ShoppingBagIcon fontSize="small" />
      },
      {
        title: 'Asset Upload',
        path: '/assetUpload',
        icon: <UserIcon fontSize="small" />
      },

    ]
  }
];

// const sections = [
//   {
//     title: 'General',
//     items: [
//       {
//         title: 'Selected Assets',
//         path: '/selectedMerchantAssets',
//         icon: <UserIcon fontSize="small" />
//       },
//       {
//         title: 'Account',
//         path: '/account',
//         icon: <UserIcon fontSize="small" />
//       }
//     ]
//   },
//   customerItems,
//   merchantItems,
//   operatorItems
// ];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {user.userType === 'C' && customerSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          {user.userType === 'M' && merchantSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          {user.userType === 'CM' && customerMerchantSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
          {user.userType === 'O' && operatorSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        {/* <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
