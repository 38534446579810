import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from '../config';
import type { User } from '../types/user';
import { gql, useMutation } from '@apollo/client';

const MUTATION_CM4USER = gql`
    mutation CreateCustomerMerchant4User($userInput: CreateUserInput!) {
        createCM4User(input: $userInput){
            firstName
            lastName
            email
            qldbCustId
            qldbMercId
            qldbOptId
            oktaID
            userType
    }
}
`;

Amplify.configure(amplifyConfig);

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (name: string, email: string, password: string, usertype: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
}
  ;
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State): State => ({ ...state }),
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [createCM4User, { loading, error, data }] = useMutation(MUTATION_CM4USER);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.
        const result = await createCM4User(
          { variables:
            {
              userInput: {
                firstName: user.attributes.name,
                lastName: '',
                email: user.attributes.email,
                mobileNumber: '',
                oktaID: user.attributes.sub,
                userType: user.attributes['custom:usertype'],
                addedBy: 'system'
              }
            }
          }
        );

        // console.log(`result: ${JSON.stringify(result)}`);

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.sub,
              avatar: '/static/mock-images/avatars/avatar-personlogo.png',
              email: user.attributes.email,
              name: user.attributes.name,
              qldbCustId: result.data.createCM4User.qldbCustId,
              qldbMercId: result.data.createCM4User.qldbMercId,
              qldbOptId: result.data.createCM4User.qldbOptId,
              oktaID: result.data.createCM4User.oktaID,
              userType: user.attributes['custom:usertype']
            }
          }
        });
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signIn(email, password);
    console.log('In login:');
    console.log(`id: ${user.attributes.sub}`);
    console.log(`custom:usertype: ${user.attributes['custom:usertype']}`);
    console.log(`name: ${user.attributes.name}`);
    console.log(`email: ${user.attributes.email}`);

    const result = await createCM4User(
      { variables:
        {
          userInput: {
            firstName: user.attributes.name,
            lastName: '',
            email: user.attributes.email,
            mobileNumber: '',
            oktaID: user.attributes.sub,
            userType: user.attributes['custom:usertype'],
            addedBy: 'system'
          }
        }
      }
    );

    if (user.challengeName) {
      console.error(`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`);
      return;
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.attributes.sub,
          avatar: '/static/mock-images/avatars/avatar-personlogo.png',
          email: user.attributes.email,
          name: user.name,
          qldbCustId: result.data.createCM4User.qldbCustId,
          qldbMercId: result.data.createCM4User.qldbMercId,
          qldbOptId: result.data.createCM4User.qldbOptId,
          oktaID: result.data.createCM4User.oktaID,
          userType: user.attributes['custom:usertype']
        }
      }
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (name: string, email: string, password: string, usertype: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email, name, 'custom:usertype': usertype }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  if (loading) return <p> preparation in progress..</p>;

  if (error) return <p> Failed the preparation. </p>;

  if (data) console.log('data is ready.');

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
