import { Box, Typography } from '@material-ui/core';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import useAuth from '../../../hooks/useAuth';

const QUERY_MERCHANT_BY_ID = gql`
    query MerchantById($merchantId: String!){
      merchantById(merchantId:$merchantId){
        name
        redeemableAmt
        totalRedeemed
      }
    }
`;

const QUERY_CUSTOMER_BY_ID = gql`
    query CustomerById($customerId: String!){
    customerById(customerId:$customerId){
        firstName
        lastName
        availableFund
    }
    }
`;

const MerchantCustomerAvailableFund = () => {
  const { user } = useAuth();

  const {
    loading: mLoading, error: mError, data: mData, refetch: mRefetch, networkStatus: mNetworkStatus
  } = useQuery(QUERY_MERCHANT_BY_ID, {
    variables: {
      merchantId: user.qldbMercId
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: cLoading, error: cError, data: cData, refetch: cRefetch, networkStatus: cNetworkStatus
  } = useQuery(QUERY_CUSTOMER_BY_ID, {
    variables: {
      customerId: user.qldbCustId
    },
    notifyOnNetworkStatusChange: true,
  });

  user.merchantRefetch = mRefetch;
  user.infoRefetch = cRefetch;

  if (mNetworkStatus === NetworkStatus.refetch) return <p>Refreshing...</p>;
  if (mLoading) return <p> Loading ... </p>;
  if (mError) return <p> Failed to retrieve the customer information..</p>;

  if (cNetworkStatus === NetworkStatus.refetch) return <p>Refreshing...</p>;
  if (cLoading) return <p> Loading ... </p>;
  if (cError) return <p> Failed to retrieve the customer information..</p>;

  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: 2
      }}
    >
      <Typography
        color="white"
        variant="h6"
        align="center"
      >
        Hi,
        {' '}
        {mData.merchantById.name}
        ,  your available/redeemable fund:
        {' '}
        $
        { (mData.merchantById.redeemableAmt + cData.customerById.availableFund) / 100}
        , redeemed amount:
        {' '}
        $
        {mData.merchantById.totalRedeemed / 100}
      </Typography>
    </Box>
  );
};

export default MerchantCustomerAvailableFund;
