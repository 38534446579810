import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
// import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import GuestGuard from './components/GuestGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
// const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
// const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
// const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
// const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// dashboard ganymede specific

const LatestAssetList = Loadable(lazy(() => import('./pages/dashboard/LatestAssetList')));
const MerchantAssetList = Loadable(lazy(() => import('./pages/dashboard/MerchantAssetList')));
const UpdateMerchantAsset = Loadable(lazy(() => import('./components/dashboard/asset/UpdateMerchantAsset')));
const AssetUploadForm = Loadable(lazy(() => import('./components/dashboard/asset/AssetUploadForm')));
const SelectedMerchantAssetList = Loadable(lazy(() => import('./pages/dashboard/SelectedMerchantAssetList')));
const CustomerPurchaseActivityList = Loadable(lazy(() => import('./pages/dashboard/CustomerPurchaseActivityList')));
const MerchantRedemptionList = Loadable(lazy(() => import('./pages/dashboard/MerchantRedemptionList')));
const MerchantSaleActivityList = Loadable(lazy(() => import('./pages/dashboard/MerchantSaleActivityList')));
const CustomerPaymentActivityList = Loadable(lazy(() => import('./pages/dashboard/CustomerPaymentActivityList')));
const AllApprovedRedemptionList = Loadable(lazy(() => import('./pages/dashboard/AllApprovedRedemptionList')));
const AllNewRedemptionList = Loadable(lazy(() => import('./pages/dashboard/AllNewRedemptionList')));
const MerchantList = Loadable(lazy(() => import('./pages/dashboard/MerchantList')));
const CustomerPayReceived = Loadable(lazy(() => import('./pages/dashboard/CustomerPayReceived')));
const CreateRedemption = Loadable(lazy(() => import('./pages/dashboard/CreateRedemption')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

// const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
// const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Social pages

// const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
// const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages
const Contact = Loadable(lazy(() => import('./pages/Contact')));

const routes: PartialRouteObject[] = [
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },

  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Overview />
          },
          {
            path: 'account',
            element: <Account />
          },
          {
            path: 'analytics',
            element: <Analytics />
          },
          { path: 'customerPayReceived', element: <CustomerPayReceived /> },
          { path: 'customerPurchaseActivities', element: <CustomerPurchaseActivityList /> },
          { path: 'customerPaymentActivities', element: <CustomerPaymentActivityList /> },
          { path: 'assets', element: <LatestAssetList /> },
          { path: 'updateAsset', element: <UpdateMerchantAsset /> },
          { path: 'assetUpload', element: <AssetUploadForm /> },
          { path: 'merchantAssets', element: <MerchantAssetList /> },
          { path: 'selectedMerchantAssets/:merchantId', element: <SelectedMerchantAssetList /> },
          { path: 'merchantList', element: <MerchantList /> },
          { path: 'merchantRedemptionList', element: <MerchantRedemptionList /> },
          { path: 'merchantSaleActivityList', element: <MerchantSaleActivityList /> },
          { path: 'createRedemption',
            element: <CreateRedemption />
          },
          { path: 'approvedRedemptions', element: <AllApprovedRedemptionList /> },
          { path: 'allNewRedemptions', element: <AllNewRedemptionList /> },
          // {
          //   path: 'merchantList',
          //   children: [
          //     {
          //       path: '/',
          //       element: <MerchantList />
          //     },
          //     {
          //       path: ':merchantId',
          //       element: <SelectedMerchantAssetList />
          //     },
          //   ]
          // },
          // {
          //   path: 'invoices',
          //   children: [
          //     {
          //       path: '/',
          //       element: <InvoiceList />
          //     },
          //     {
          //       path: ':invoiceId',
          //       element: <InvoiceDetails />
          //     }
          //   ]
          // },
          // {
          //   path: 'kanban',
          //   element: <Kanban />
          // },
          // {
          //   path: 'mail',
          //   children: [
          //     {
          //       path: '/',
          //       element: (
          //         <Navigate
          //           to="/mail/all"
          //           replace
          //         />
          //       )
          //     },
          //     {
          //       path: 'label/:customLabel',
          //       element: <Mail />
          //     },
          //     {
          //       path: 'label/:customLabel/:emailId',
          //       element: <Mail />
          //     },
          //     {
          //       path: ':systemLabel',
          //       element: <Mail />
          //     },
          //     {
          //       path: ':systemLabel/:emailId',
          //       element: <Mail />
          //     }
          //   ]
          // },
          // {
          //   path: 'orders',
          //   children: [
          //     {
          //       path: '/',
          //       element: <OrderList />
          //     },
          //     {
          //       path: ':orderId',
          //       element: <OrderDetails />
          //     }
          //   ]
          // },
          {
            path: 'finance',
            element: <Finance />
          }
          // {
          //   path: 'products',
          //   children: [
          //     {
          //       path: '/',
          //       element: <ProductList />
          //     },
          //     {
          //       path: 'new',
          //       element: <ProductCreate />
          //     }
          //   ]
          // },
          // {
          //   path: 'projects',
          //   children: [
          //     {
          //       path: 'browse',
          //       element: <ProjectBrowse />
          //     },
          //     {
          //       path: 'new',
          //       element: <ProjectCreate />
          //     },
          //     {
          //       path: ':projectId',
          //       element: <ProjectDetails />
          //     }
          //   ]
          // },
          // {
          //   path: 'social',
          //   children: [
          //     {
          //       path: 'feed',
          //       element: <SocialFeed />
          //     },
          //     {
          //       path: 'profile',
          //       element: <SocialProfile />
          //     }
          //   ]
          // }
        ]
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
