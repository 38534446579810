export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const CAT_NAME_DESC = new Map<string, string>();

CAT_NAME_DESC.set('OSS', 'Open Source Software');
CAT_NAME_DESC.set('CAN', 'Current Affair - News');
CAT_NAME_DESC.set('CAO', 'Current Affair - Opinion');
CAT_NAME_DESC.set('CAR', 'Current Affair - Review');
CAT_NAME_DESC.set('CLP', 'Classic Literature - Philosophy');
CAT_NAME_DESC.set('CLF', 'Classic Literature - Fiction');
CAT_NAME_DESC.set('CLG', 'Classic Literature - General');

CAT_NAME_DESC.set('FAR', 'Financial Reports & Advices');
CAT_NAME_DESC.set('TGG', 'Technologies - General');
CAT_NAME_DESC.set('TUL', 'Tutorials - Language');
CAT_NAME_DESC.set('TUA', 'Tutorials - Art');
CAT_NAME_DESC.set('TUM', 'Tutorials - Math');
CAT_NAME_DESC.set('TUP', 'Tutorials - Programming');
CAT_NAME_DESC.set('TUG', 'Tutorials - Genral');
CAT_NAME_DESC.set('FIC', 'Fiction');
CAT_NAME_DESC.set('GEN', 'General');
