import { Box, Typography } from '@material-ui/core';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import useAuth from '../../../hooks/useAuth';

const QUERY_MERCHANT_BY_ID = gql`
    query MerchantById($merchantId: String!){
      merchantById(merchantId:$merchantId){
        name
        redeemableAmt
        totalRedeemed
      }
    }
`;

const MerchantAvailableFund = () => {
  const { user } = useAuth();

  const {
    loading, error, data, refetch, networkStatus
  } = useQuery(QUERY_MERCHANT_BY_ID, {
    variables: {
      merchantId: user.qldbMercId
    },
    notifyOnNetworkStatusChange: true,
  });

  user.infoRefetch = refetch;

  if (networkStatus === NetworkStatus.refetch) return <p>Refreshing...</p>;

  if (loading) return <p> Loading ... </p>;
  if (error) return <p> Failed to retrieve the customer information..</p>;

  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: 2
      }}
    >
      <Typography
        color="white"
        variant="h6"
        align="center"
      >
        Hi,
        {' '}
        {data.merchantById.name}
        ,  your redeemable fund:
        {' '}
        $
        {data.merchantById.redeemableAmt / 100}
        , redeemed amount:
        {' '}
        $
        {data.merchantById.totalRedeemed / 100}
      </Typography>
    </Box>
  );
};

export default MerchantAvailableFund;
