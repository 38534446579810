import { Box, Typography } from '@material-ui/core';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import useAuth from '../../../hooks/useAuth';

const QUERY_CUSTOMER_BY_ID = gql`
    query CustomerById($customerId: String!){
    customerById(customerId:$customerId){
        firstName
        lastName
        availableFund
    }
    }
`;

const CustomerAvailableFund = () => {
  const { user } = useAuth();

  const {
    loading, error, data, refetch, networkStatus
  } = useQuery(QUERY_CUSTOMER_BY_ID, {
    variables: {
      customerId: user.qldbCustId
    },
    notifyOnNetworkStatusChange: true,
  });

  user.infoRefetch = refetch;

  if (networkStatus === NetworkStatus.refetch) return <p>Refreshing...</p>;

  if (loading) return <p> Loading ... </p>;
  if (error) return <p> Failed to retrieve the customer information..</p>;

  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: 2
      }}
    >
      <Typography
        color="white"
        variant="h6"
        align="center"
      >
        Hi,
        {' '}
        {data.customerById.firstName}
        ,  your available fund:
        {' '}
        $
        {data.customerById.availableFund / 100}
      </Typography>
    </Box>
  );
};

export default CustomerAvailableFund;
